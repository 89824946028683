import { AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";
import Hub from "./Hub";
import Login from "./login";
//import { Mobile } from ".../components/mobile/mobile.component";

export const Home = () => {
    return (
        <>
            <AuthenticatedTemplate>
                <Hub />
            </AuthenticatedTemplate>
            <UnauthenticatedTemplate>
                <Login />
            </UnauthenticatedTemplate>
        </>
    );
}