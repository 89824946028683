import React, { Component } from "react";
import "../Card.css";
import "bootstrap/dist/css/bootstrap.min.css";

function AppCard({ name, style, link, href }) {
  return (
    //  <div className='col-sm col-md col-lg col-xl'>
    <div
      className="card"
      style={{
        display: "flex",
        justifyContent: "center",
        alignContent: "center",
        backgroundColor: "transparent",
        border: "none",
        margin: "2.5%",
      }}
    >
      <a class="mainDiv" style={style} href={href}>
        {/* <div> */}
        <p style={{ textAlign: "center", color: "inherit" }}>{name}</p>
        {/* </div> */}
      </a>
    </div>
  );
}

export default AppCard;
