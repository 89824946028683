import "../App.css";
import React, { useState, useEffect } from "react";
import { useMsal } from "@azure/msal-react";
import Card from "../components/Card";
import "../Card.css";
import Navbar from "../components/NavBar";

function Hub()
{
  const [loading, setLoading] = useState(true);
  const [userRole, setUserRole] = useState("None");

  useEffect(() =>
  {
    fetchToken();
  }, []);

  const fetchToken = async () =>
  {
    // const url = "https://api.uamishub.com/api/User/authenticate";
    const url = process.env.REACT_APP_UAMISHUB_UserAuthentication_Api_Url;

    await fetch(url, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        Id: accounts[0].localAccountId,
      }),
    })
      .then((response) =>
      {
        console.log("Made it to the token: ", response);
        return response.json();
      })
      .then(function (json)
      {
        console.log(json.token);
        localStorage.setItem("userAccessToken", json.token);
      })
      .catch(function (e)
      {
        console.log(e);
      })
      .finally(() =>
      {
        fetchRole();
      });
  };

  const fetchRole = async () =>
  {
    const url = process.env.REACT_APP_UserID_Url + "/" + `${accounts[0].localAccountId}`;
    // const url = `https://api.uamishub.com/api/User/${accounts[0].localAccountId}`;

    await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("userAccessToken"),
      },
    })
      .then(function (response)
      {
        console.log(response);
        if (response.status !== 401) return response.json();

        fetchToken();
        throw Error;
      })
      .then(function (json)
      {
        setUserRole(json[0].role);
      })
      .catch(function (error)
      {
        console.log(error);
      })
      .finally(function ()
      {
        setLoading(false);
      });
  };

  const { accounts } = useMsal();

  const temp = accounts[0].name;

  const firstName = temp.split(" ")[0];

  const AttendanceApp_Url = process.env.REACT_APP_AttendanceApp_Url;

  const UserPortal_Url = process.env.REACT_APP_UserPortal_Url;

  const MockInterviews_Url = process.env.REACT_APP_MockInterviews_Url;

  return (
    <>
      <div class="bground">
        <Navbar userRole={userRole} />
        <h1 style={{ marginLeft: "5%", color: "#660000" }}>
          {" "}
          Welcome {firstName}, to the UAMIS Hub!
        </h1>
        <h3 style={{ marginLeft: "5%", fontWeight: 300, color: "#660000" }}>
          {" "}
          Your one-stop-shop for all things UAMIS
        </h3>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            height: "auto",
            marginTop: "2.5%",
            maxWidth: "100%",
            justifyContent: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              width: "90%",
              maxWidth: "90%",
              flexWrap: "wrap",
              zIndex: 4,
              height: "100%",
            }}
          >
            {/* <div></div> */}
            <Card
              style={{ marginBottom: "5%", zIndex: 4 }}
              // href="https://www.attendanceapp.uamishub.com/"
              // href="https://www.attendanceapp.qa.uamishub.com/"
              href={AttendanceApp_Url}
              name="Attendance"
            />
            <Card
              style={{ marginBottom: "5%", zIndex: 4 }}
              // href="https://www.signupgenius.com/go/10c0d4faeab22a6f5c16-spring#/"
              href={MockInterviews_Url}
              name="Mock Interviews"
            />
            <Card
              style={{ marginBottom: "5%", zIndex: 4 }}
              href={"https://ua-mis.square.site/"}
              name="Aims Dues & Merch"
            />
            <Card
              style={{ marginBottom: "5%", zIndex: 4 }}
              href={"https://give.ua.edu/"}
              name="Alumni Donations"
            />
            {/* <Card
              style={{ marginBottom: "5%", zIndex: 4 }}
              name="Mentorship Program"
            /> */}
            {/* <Card
              style={{ marginBottom: "5%", zIndex: 4 }}
              name="Resume Book"
            /> */}
            <Card
              style={{ marginBottom: "5%", zIndex: 4 }}
              href={UserPortal_Url}
              name="User Portal"
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default Hub;
