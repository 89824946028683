import './App.css';
import React, { useEffect } from "react";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
//import { InteractionRequiredAuthError } from "@azure/msal-browser";
import { Home } from './screens/Home';
//import { usemediaQuery } from 'react-responsive';


// dummy commit 

function App() {
  const { instance } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  // const isMobile = useMedia({
  //   query: "(min-device-width: 480px)",
  // });

  useEffect(() => {
    if (!isAuthenticated) {
        instance.ssoSilent({
            scopes: ["user.read"],
            // redirectUri: 'http://localhost:3000/blank.html'
            // redirectUri: 'https://uamishub.com/blank.html'
            // loginHint: "", 
        }).then((response) => {
            instance.setActiveAccount(response.account);
        })
    }
    // eslint-disable-next-line
    }, []);

  return (

    <Home />
  );
}

export default App;

