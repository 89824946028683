import "../nav.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { useMsal } from "@azure/msal-react";

const NavBar = ({ userRole }) =>
{

  const { instance } = useMsal();
  // const AttendanceApp = "https://www.attendanceapp.uamishub.com/";
  const AttendanceApp = process.env.REACT_APP_AttendanceApp_Url;
  const UserPortal = process.env.REACT_APP_UserPortal_Url;

  const handleLogout = (instance) =>
  {
    instance.logoutRedirect().catch((e) =>
    {
      console.error(e);
    });
  };
  const MockInterviews_Url = process.env.REACT_APP_MockInterviews_Url;

  return (
    <>
      <div
        style={{
          marginBottom: 100,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          alignContent: "center",
        }}
      >
        {userRole === "admin" ? (
          <Navbar expand="lg">
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse
              id="basic-navbar-nav"
              style={{
                backfaceVisibility: "visible",
                backgroundImage: "linear-gradient(#ff9999, #ff9998 white)",
                zIndex: 5,
                borderColor: "black",
              }}
            >
              <Nav
                className="mainNav"
                style={{
                  marginTop: "1%",
                  width: "100%",
                }}
              >
                <Nav.Link href={AttendanceApp}>
                  <a id="navA">Attendance</a>
                </Nav.Link>
                <Nav.Link href={MockInterviews_Url}>
                  <a id="navA">Mock Interviews</a>
                </Nav.Link>
                <Nav.Link href="https://ua-mis.square.site/">
                  <a id="navA">Aims Dues & Merch</a>
                </Nav.Link>
                <Nav.Link href={"https://give.ua.edu/"}>
                  <a id="navA">Alumni Donations</a>
                </Nav.Link>
                {/* <Nav.Link>
                    <a id="navA">360s</a>
                  </Nav.Link> */}
                {/* <Nav.Link>
                    <a id="navA">Resume Book</a>
                  </Nav.Link> */}
                <Nav.Link href={UserPortal}>
                  <a id="navA">User Portal</a>
                </Nav.Link>
                <Nav.Link style={{}}>
                  <a id="navA" onClick={() => handleLogout(instance)}>
                    Logout
                  </a>
                </Nav.Link>
              </Nav>
            </Navbar.Collapse>
          </Navbar>
        ) : userRole === "student" ? (
          <Navbar expand="lg">
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse
              id="basic-navbar-nav"
              style={{
                backfaceVisibility: "visible",
                backgroundImage: "linear-gradient(#ff9999, #ff9998 white)",
              }}
            >
              <Nav className="mainNav" style={{ marginLeft: "1%", marginTop: "1%" }}>
                <Nav.Link>
                  <a id="navA" href={AttendanceApp}>
                    Attendance
                  </a>
                </Nav.Link>
                <Nav.Link>
                  <a
                    id="navA"
                    onClick={() => handleLogout(instance)}
                  >
                    Logout
                  </a>
                </Nav.Link>
              </Nav>
            </Navbar.Collapse>
          </Navbar>
        ) : (
          <Navbar expand="lg">
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse
              id="basic-navbar-nav"
              style={{
                backfaceVisibility: "visible",
                backgroundImage: "linear-gradient(#ff9999, #ff9998 white)",
              }}
            >
              <Nav className="mainNav" style={{ marginLeft: "1%", marginTop: "1%" }}>
                <Nav.Link href="https://ua-mis.square.site/">
                  <a id="navA">Aims Dues & Merch</a>
                </Nav.Link>
                <Nav.Link href={"https://give.ua.edu/"}>
                  <a id="navA">Alumni Donations</a>
                </Nav.Link>
                <Nav.Link
                >
                  <a
                    id="navA"
                    onClick={() => handleLogout(instance)}
                  >
                    Logout
                  </a>
                </Nav.Link>
              </Nav>
            </Navbar.Collapse>
          </Navbar>
        )}
      </div>
    </>
  );
};

export default NavBar;
