import { LogLevel, EventType } from "@azure/msal-browser";

export const msalConfig = {
  auth: {
    clientId: "1d60cb5b-d392-4a64-a569-3b75b689920a",
    authority:
      "https://login.microsoftonline.com/2a00728e-f0d0-40b4-a4e8-ce433f3fbca7", // This is a URL (e.g. https://login.microsoftonline.com/{your tenant ID})
    // redirectUri: "https://uamishub.com/",
    // redirectUri: "http://localhost:3000/",
    redirectUri: process.env.REACT_APP_Redirect_Url
  },
  cache: {
    cacheLocation: "localStorage", // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
  system: {
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            console.error(message);
            return;
          case LogLevel.Info:
            console.info(message);
            return;
          case LogLevel.Verbose:
            console.debug(message);
            return;
          case LogLevel.Warning:
            console.warn(message);
            return;
        }
      },
    },

    iframeHashTimeout: 10000,
  },
};

// msalConfig.addEventCallback((event) => {
//   if (event.eventType === EventType.LOGIN_SUCCESS) {
//       msalConfig.setActiveAccount(event.payload.account);
//   }
// });

// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
export const loginRequest = {
  scopes: ["User.Read"],
};

// Add the endpoints here for Microsoft Graph API services you'd like to use.
export const graphConfig = {
  graphMeEndpoint: "https://graph.microsoft.com/v1.0/me",
};
