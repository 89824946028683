import React from 'react';
import Button from 'react-bootstrap/esm/Button';
//import logo from './src/Images/MicrosoftLogo.jpg';
import background from '../Images/LogInBackground.png';
import logo from '../Images/MLogo2.png';
import { loginRequest } from "../authConfig";
import MicrosoftLogin from "react-microsoft-login";
import '../login.css';
import { useMsal } from '@azure/msal-react';


function Login()
{
    const { instance } = useMsal();

    const handleLogin = (instance) => {
        instance.loginRedirect(loginRequest).catch(e => {
          console.log(e);
        });
     }

    return (
    <>
        <div style={{ height: '100%S', width: '100%',  display: 'inline-flex', justifyContent: 'center', alignContent: 'center'}}>
                <img src={background} style={{ objectFit: 'cover', width: '100%', minHeight: '100vh', position: 'absolute', zIndex: 0, display: 'flex'}} />
                <div style={{ borderRadius: 20,  boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.50)", backgroundColor: 'white', height: "50%", width: "clamp(350px, 40%, 600px)", marginTop: '15%', zIndex: 1}}>
                    <h2 style={{ textAlign: 'center', marginTop:'5%', fontWeight: 400, fontFamily: 'Poppins', fontSize: '36px', flex: 1, textSizeAdjust: 'auto' }}>Login</h2>
                    <h3  style={{  textAlign: 'center', marginTop: '5%', fontWeight: 200, fontFamily: 'Poppins', fontSize: '30px', flex: 1, textSizeAdjust: 'auto' }}>Welcome to the UAMIS Hub</h3>
                    {/* <p style={{ textAlign: 'center', margin: 20, fontFamily: 'Poppins', fontWeight: 300, fontSize: '200%', flex: 1, textSizeAdjust: '50%', WebkitTextSizeAdjust: '50%' }}>Welcome to UAMIS Hub!</p> */}
                    <div style={{ display: 'flex', justifyContent: 'center', margin: '5%'}}>
                        {/* <Button style={{ width: 300, whiteSpace: 'nowrap'}}><span><img src={logo} style={{ display: 'inline-flex', width: '12.5%', margin: 'auto'}}/></span>Login with Microsoft</Button> */}
                        <i style={{ display: 'flex', marginLeft: 'auto', marginRight: 'auto', cursor: 'pointer'}} onClick={() => handleLogin(instance)}><img src={logo} style={{ width: '60%', margin: 'auto', imageResolution: 'from-image', imageRendering: 'clean' , borderRadius: '10px', boxShadow: "0 6px 10px -3px gray"}}/></i>
                    </div>
                </div>
            </div>
    </>
    );
}

export default Login;